<template>
    <q-card>
        <q-card-section class="col-12 row">
            <div class="top-ranking col-12 row flex">
                <div class="flex row col-6">
                    <div class="col-12 row flex">
                        <div class="col-6 row flex">
                            <div class="col-12 title-card row justify-center">
                                <label>Top 1 estudante</label>
                            </div>
                            <div class="flex col-12 row justify-center q-pb-md">
                                <q-avatar size="25vh">
                                    <img src="https://cdn.quasar.dev/logo-v2/svg/logo.svg" />
                                </q-avatar>
                            </div>
                            <div class="flex col-12 row justify-center">
                                <h4>{{ studant_top_1.user_name }}</h4>
                            </div>
                            <!-- <div class="flex col-12 row justify-center">
                                <h5>Grupo do Bolinha</h5>
                            </div>
                            <div class="flex col-12 row justify-center">
                                <h6>Nível: 7</h6>
                            </div> -->
                        </div>
                        <div class="col-6 flex">
                            <div class="flex col-12 row items-center">
                                <div class="column col-12">
                                    <h4>Cursos realizados: {{ studant_top_1.number_courses_completed }}</h4>
                                    <h4>Cursos iniciados: {{ studant_top_1.number_courses_started }}</h4>
                                    <h4>Interações Post: {{ studant_top_1.number_posts_interacted }}</h4>
                                    <h4>Wiki realizados: {{ studant_top_1.number_wiki_completed }}</h4>
                                    <h4>Messagens chat: {{ studant_top_1.number_chat_menssages }}</h4>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="flex row col-6">
                    <div class="col-12 title-card row justify-center">
                        <label>Top 5 estudantes de interação no chat</label>
                    </div>
                    <div class="flex col-12 list-ranking row">
                        <div class="col-6 row" v-for="item in studant_ranking" :key="item.user_name">
                            <div>
                                <h4><b>{{item.ranking}} .</b>{{ item.user_name }}</h4>
                                <label>Messagens chat: {{ item.number_chat_menssages }}</label>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </q-card-section>
    </q-card>
</template>
<script>
import { onMounted, ref } from "vue";
export default {
    props: {
        items: Array
    },
    setup(props) {

        let studant_top_1 = ref([]);
        let studant_ranking = ref([]);

        function separationStudantsRanking() {
            for (let i = 0; i < props.items.length; i++) {
                if (i == 0) {
                    studant_top_1.value = props.items[i];
                } else {
                    studant_ranking.value.push(props.items[i]);
                }
            }
        }

        onMounted(() => {
            setTimeout(() => {
                separationStudantsRanking();
            }, 1000);

        })

        return {
            studant_top_1,
            studant_ranking
        }
    }
}
</script>
<style lang="scss" scoped>
.list-ranking {
    height: 30vh;
    overflow-y: auto;
}

.top-ranking {


    .title-card {
        label {
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            color: var(--q-primary, #fe4e64);
        }
    }

    h4 {
        font-weight: 600;
        font-size: 14px;
        line-height: 30px;
        color: #686868;
    }

    h5 {
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        color: #686868;
    }

    h6 {
        font-weight: 600;
        font-size: 8px;
        line-height: 12px;
        color: #686868;
    }

}
</style>