<template>
    <q-card class="card-simple-dashboard" :style="'width:' + width + '; height: ' + height">
        <q-card-section>
            <div class="flex row ">
                <div class="title-card col-12 q-mx-sm">{{ title }}</div>
                <div class="value-card col-12 q-mx-sm" v-if="percent == true">{{ newText }}%</div>
                <div class="value-card col-12 q-mx-sm" v-else>{{ newText }}</div>
            </div>
        </q-card-section>
    </q-card>
</template>
<script>
import { ref, onMounted } from "vue";
export default {
    props: {
        text: String,
        title: String,
        percent: Boolean,
        width: String,
        height: String
    },
    setup(props) {
        let newText = ref(null)
        onMounted(() => {
            setTimeout(() => {
                if (props.text == null) {
                    newText.value = 0
                } else if (props.percent) {
                    newText.value = parseFloat(props.text.toFixed(2))
                }else{
                    newText.value = props.text
                }
            }, 1000);
        })

        return {
            newText
        }
    }
}
</script>
<style lang="scss" scoped>
.card-simple-dashboard {
    .title-card {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #2F2F2F;
    }

    .value-card {
        font-weight: 400;
        font-size: 40px;
        color: #000000;
        text-align: center;
    }
}
</style>