<template>
    <q-card class="col-12">
        <q-card-section class="col-12">
            <div class="col-12 title-card flex row justify-start q-pb-md">
                <label>{{ title }}</label>
            </div>
            <div class="col-12 row flex justify-around">
                <div v-for="(value, key) in items" :key="key">
                    <div>
                        <label class="title-label">{{key}}</label>
                    </div>
                    <div class="text-center q-mt-sm">
                        <label class="text-label ">{{value}}</label>
                    </div>

                </div>
            </div>
        </q-card-section>
    </q-card>
</template>
<script>
import { ref, onMounted } from "vue";
export default {
    props: {
        title: String,
        items: Object
    },
}
</script>
<style lang="scss" scoped>
.title-card {
    label {
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        color: var(--q-primary, #fe4e64);
    }
}

.title-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #686868;
}

.text-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;

}
</style>